@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: rgb(37, 37, 37);
  color: white;
  width: 100%;
  height: 100%;
  color: white;
  /* font-family: "Nunito", sans-serif; */
  font-family: "Rubik", sans-serif;
}

/* Desktop screen */
@media screen and (min-width: 1024px) {
    
  .head {
    margin: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  
  .head h1 {
    font-size: 45px;
  }
  
  .head .title {
    color: rgb(99, 248, 99);
  }
  
  .head-p {
    margin: 30px;
  }
  
  .head-p p {
    margin: 10px;
    font-size: 20px;
  }

}

/* Tablet screen */
@media screen and (min-width: 768px) and (max-width: 1023px) {

  .head {
    margin: 40px 0 40px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  .head h1 {
    font-size: 45px;
  }

  .head .title {
    color: rgb(99, 248, 99);
  }

  .head-p {
    margin: 40px 0 40px 0;
  }

  .head-p p {
    font-size: 20px;
    margin: 10px 0 10px 0;
  }
  
}

/* Mobile screen */
@media screen and (max-width: 767px) {

  .head {
    margin: 40px 0 40px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  .head h1 {
    font-size: 35px;
  }

  .head .title {
    color: rgb(99, 248, 99);
  }

  .head-p {
    margin: 30px 0 30px 0;
  }

  .head-p p {
    font-size: 15px;
    margin: 5px 0 5px 0;
  }
}